export class TokenParser {
    private buffer: string;
    private offset: number;
    private length: number;
    
    constructor(s: string) {
        this.buffer = s;
        this.offset = 0;
        this.length = s.length;
    }
    
    public getBuffer(): string {
        return this.buffer;
    }
    
    public getBufferCurrentSlice(): string {
        return this.buffer.slice(this.offset);
    }
    
    public takeNext(delim: string): string {
        for (let i = this.offset; i <= this.length; ++i) {
            if (this.buffer[i] === delim || i === this.length) {
                const ret = this.buffer.slice(this.offset, i);
                this.offset = i + 1;
                return ret;
            }
        }
        
        return '';
    }
    
    public hasNext(delim: string): boolean {
        for (let i = this.offset; i <= this.length; ++i) {
            if (this.buffer[i] === delim || i === this.length) {
                return true;
            }
        }
        
        return false;
    }
    
    public takeAll(delim: string): string[] {
        let ret = [];
        while (true) {
            const next = this.takeNext(delim);
            if (next === '') {
                break;
            }
            
            ret.push(next);
        }
        
        return ret;
    }
    
    public takeNextEnclosed(start: string, end: string): string {
        if (this.length - this.offset < 2 || this.buffer[this.offset] !== start) {
            return '';
        }
        
        for (let i = this.offset + 1; i < this.length; ++i) {
            if (this.buffer[i] === end) {
                const ret = this.buffer.slice(this.offset + 1, i);
                this.offset = i + 1;
                return ret;
            }
        }
        
        return '';
    }
    
    public hasNextEnclosed(start: string, end: string): boolean {
        if (this.length - this.offset < 2 || this.buffer[this.offset] !== start) {
            return false;
        }
        
        for (let i = this.offset + 1; i < this.length; ++i) {
            if (this.buffer[i] === end) {
                return true;
            }
        }
        
        return false;
    }
}