











import Vue from 'vue'
export default Vue.extend({
    props: {
        "count": {
            type: Number as () => number,
        },
    },
    computed: {
        isLong(): boolean {
            return this.count > 6;
        },
    },
});
